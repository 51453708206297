
@import "../../assets/scss/_variables.scss";

.project-slide {
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	overflow: hidden;
	backface-visibility: hidden;
	mask-image: -webkit-radial-gradient(white, black);
	-webkit-backface-visibility: hidden;
	-webkit-mask-image: -webkit-radial-gradient(white, black);
	opacity: 0;
	transition: all 0.5s;
	pointer-events: none;

	@media screen and (min-width: $breakpoint-min-lg) {
		border-radius: 20px;
	}

	&.current {
		opacity: 1;
	}

	.slide-background-content {
		width: 100%; height: 100%;
		z-index: 8;

		img, video {
			width: 100%; height: 100%;
			object-fit: cover;
		}
	}

	.slide-text-background {
		position: absolute;
	}

	.modal-text {
		z-index: 9;
		width: 85%;
		font-size: 1rem;
		line-height: 1.65rem;

		@media screen and (min-width: $breakpoint-min-sm) {
			width: 75%;
			font-size: 1.25rem;
			line-height: 2rem;
		}

		@media screen and (min-width: $breakpoint-min-md) {
			width: 60%;
			font-size: 1.5rem;
			line-height: 2.3rem;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			width: 45%;
			font-size: 1.5rem;
			line-height: 2.35rem;
		}
	}

	.meta {
		.role span {
			opacity: 0.5;
			display: block;
		}

		.credits {
			margin-top: $content-base-padding-lg;
			opacity: 0.5;
		}
	}
}
