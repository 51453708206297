
@import "../../assets/scss/_variables.scss";

#modal {
	.modal-control {
		position: relative;
		z-index: 10;
		transition: opacity 0.5s ease-out;
	}

	#modal-title {
		opacity: 0.5;
		padding-left: $content-base-padding-sm;
		line-height: 55px;

		@media screen and (min-width: $breakpoint-min-lg) {
			padding-left: $content-base-padding-md;
			line-height: 80px;
		}
	}

	#modal-close {
		opacity: 0.5;
		position: absolute;
		top: 0; right: 0;
		transition: opacity 0.15s ease-out;
		cursor: pointer;

		#icon-x { display: block; }
		#icon-circle { display: none; }
		img { padding: 20px; }

		&:hover {
			opacity: 1;
			transition: opacity 0.15s ease-out;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			#icon-x { display: none; }
			#icon-circle { display: block; }
			img { padding: $content-base-padding-lg / 2; }
		}
	}

	.modal-arrow {
		opacity: 0.5;
		position: absolute;
		transition: opacity 0.15s ease-out;
		cursor: pointer;
		top: 0; bottom: 0;
		height: 100%;
		z-index: 9;

		&:hover {
			opacity: 1;
			transition: opacity 0.15s ease-out;
		}

		@media screen and (max-width: $breakpoint-max-md) {
			opacity: 0;
			pointer-events: none;
		}

		&#modal-arrow-left { left: 0; }
		&#modal-arrow-right { right: 0; }

		img {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			padding: $content-base-padding-lg / 2;
		}
	}

	#modal-pagination {
		position: absolute;
		left: $content-base-padding-sm;
		bottom: $content-base-padding-sm;
		right: $content-base-padding-sm;
		display: flex;
		margin: auto;

		@media screen and (min-width: $breakpoint-min-sm) { width: 60%; }
		@media screen and (min-width: $breakpoint-min-md) { width: 40%; }

		@media screen and (min-width: $breakpoint-min-lg) {
			width: auto;
			text-align: center;
			display: block;
		}

		.project-page {
			opacity: 0.15;
			height: 2px;
			width: 100%;
			background: #fff;
			margin-right: $content-base-padding-sm / 2;

			&.active {
				opacity: 0.5;
			}

			&:last-child {
				margin-right: 0;
			}

			@media screen and (min-width: $breakpoint-min-lg) {
				width: 5px;
				height: 5px;
				border-radius: 100%;
				display: inline-block;
			}
		}
	}
}
