
@import "../../assets/scss/_variables.scss";

body.modal-open {
	overflow: hidden;
	#projects { overflow: hidden; }
}

#project {
	#modal {
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 5;
		top: 0; right: 0; bottom: 0; left: 0;

		@media screen and (min-width: $breakpoint-min-lg) {
			width: 90%;
			height: 85%;
			max-width: 1200px;
			max-height: 800px;
			margin: auto;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			&.ui-hidden {
				.modal-control {
					opacity: 0;
					transition: opacity 0.75s ease-out;
				}
			}
		}

		@media screen and (max-width: $breakpoint-max-md) {
			&.ui-hint {
				.modal-arrow {
					transition: opacity 0.75s ease-out;
					display: block;
					opacity: 0.5;
				}
			}
		}

		#slides {
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			background: transparent;
			transition: background 0.5s;
			overflow: hidden;

			@media screen and (min-width: $breakpoint-min-lg) {
				-webkit-mask-image: -webkit-radial-gradient(white, black);
				-webkit-backface-visibility: hidden;
				border-radius: 20px;
			}

			&.project-switching {
				.slide-content {
					opacity: 0;
					transition: all 0.1s;
				}
			}

			.slide-content {
				opacity: 1;
				transition: all 1s;
			}
		}

		#click-overlay div {
			position: absolute;
			top: 0; bottom: 0;
			z-index: 6;

			&#left {
				left: 0;
				width: 30%;
			}

			&#right {
				right: 0;
				width: 70%;
			}
		}
	}

	#overlay {
		position: fixed;
		top: 0; right: 0; bottom: 0; left: 0;
		background: rgba(0,0,0,0.75);
		z-index: 4;
	}
}
