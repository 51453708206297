
@import "../assets/scss/_variables.scss";
@import "../assets/scss/_animation.scss";

nav {
	$nav-font-size-xs: 1rem;
	$nav-font-size-regular: 1.125rem;

	font-size: $nav-font-size-xs;

	@media screen and (min-width: $breakpoint-min-sm) {
		font-size: $nav-font-size-regular;
	}

	.corner {
		position: absolute;
		z-index: 3;

		@media screen and (max-width: $breakpoint-max-sm) {
			&#title { top: $content-base-padding-sm; left: $content-base-padding-sm; }
			&#social { top: $content-base-padding-sm; right: $content-base-padding-sm; }
		}

		@media screen and (max-width: $breakpoint-max-md) {
			&#email { display: none; }
			&#meta { display: none; }
		}

		@media screen and (min-width: $breakpoint-min-sm) {
			&#title { top: $content-base-padding-md; left: $content-base-padding-md; }
			&#social { top: $content-base-padding-md; right: $content-base-padding-md; }
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			position: fixed;
			&#title { top: $content-base-padding-lg; left: $content-base-padding-lg; }
			&#email { top: $content-base-padding-lg; right: $content-base-padding-lg; }
			&#meta { bottom: $content-base-padding-lg; left: $content-base-padding-lg; }
			&#social { top: auto; bottom: $content-base-padding-lg; right: $content-base-padding-lg; }
		}
	}

	#title span {
		opacity: 0.5;
		display: block;
		margin-top: 7px;
		font-size: 0.9rem;

		@media screen and (min-width: $breakpoint-min-sm) {
			display: inline;
			margin-top: 0;
			margin-left: 5px;
			font-size: $nav-font-size-regular;
		}
	}

	#meta {
		img {
			vertical-align: 1px;
			margin-right: 5px;
		}
	}

	.icons {
		margin-right: -15px;
		a { padding: 15px; }
		line-height: 45px;

		@media screen and (min-width: $breakpoint-min-sm) {
			line-height: $nav-font-size-regular;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			#icon-email { display: none; }
		}
	}
}
