
@import "./assets/scss/_fonts.scss";
@import "./assets/scss/_variables.scss";

html, body {
	scroll-behavior: smooth;
	margin: 0; padding: 0;
	overscroll-behavior: none;
	color: #fff;
	font-family: 'Aeonik', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	letter-spacing: 0.2px;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	background-color: #010824;
}

a {
	color: #fff;
	text-decoration: none;
	cursor: pointer;
	transition: opacity 0.15s ease-out;

	&:hover {
		opacity: 0.5;
		transition: opacity 0.15s ease-out;
	}
}

.btn {
	letter-spacing: 2px;
	text-transform: uppercase;
	font-size: 0.8rem;

	@media screen and (min-width: $breakpoint-min-md) {
		font-size: 0.875rem;
	}

	img {
		margin-right: 10px;
		vertical-align: middle;
		width: 35px; height: 35px;

		@media screen and (min-width: $breakpoint-min-md) {
			width: 40px; height: 40px;
		}
	}
}

.nowrap {
	@media screen and (min-width: $breakpoint-min-sm) {
		white-space: nowrap;
	}

	&.strict {
		white-space: nowrap;
	}
}

#content {
	position: relative;
	z-index: 1;
}
