
@import "../assets/scss/_variables.scss";

#profile {
	margin: $content-base-padding-sm * 3 auto;

	@media screen and (min-width: $breakpoint-min-md) {
		margin: $content-base-padding-md * 3 auto;
	}

	@media screen and (min-width: $breakpoint-min-lg) {
		margin: $content-base-padding-lg * 3 auto;
	}

	@media screen and (min-width: $breakpoint-min-xxl) {
		margin: $content-base-padding-lg * 4 auto;
	}

	.container-fluid {
		max-width: 500px;
		padding-left: $content-base-padding-sm * 1.5;
		padding-right: $content-base-padding-sm * 1.5;

		@media screen and (min-width: $breakpoint-min-md) {
			max-width: 960px;
			padding-left: $content-base-padding-md;
			padding-right: $content-base-padding-md;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			padding-left: $content-base-padding-lg;
			padding-right: $content-base-padding-lg;
		}
	}

	p {
		font-size: 1.1rem;
		line-height: 1.6rem;
		margin-bottom: $content-base-padding-sm;

		@media screen and (min-width: $breakpoint-min-md) {
			font-size: 1.25rem;
			line-height: 1.85rem;
			margin-bottom: $content-base-padding-md;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			font-size: 1.4rem;
			line-height: 2rem;
			margin-bottom: $content-base-padding-lg;
		}
	}

	#logos {
		max-width: 960px;
		margin: auto;
		margin-top: $content-base-padding-sm;
		margin-bottom: $content-base-padding-sm * 3;

		padding-left: $content-base-padding-sm * 1.5;
		padding-right: $content-base-padding-sm * 1.5;

		@media screen and (min-width: $breakpoint-min-md) {
			max-width: 960px;
			padding-left: $content-base-padding-md;
			padding-right: $content-base-padding-md;
			margin-bottom: $content-base-padding-md * 3;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			padding-left: $content-base-padding-lg;
			padding-right: $content-base-padding-lg;
			margin-bottom: $content-base-padding-lg * 3;
		}

		img {width: 100%;}
	}

	#services {
		margin-top: $content-base-padding-sm;
		margin-bottom: $content-base-padding-sm * 1.5;

		@media screen and (min-width: $breakpoint-min-md) {
			margin-bottom: $content-base-padding-md * 1.5;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			margin-bottom: $content-base-padding-lg * 1.5;
		}

		@media screen and (max-width: $breakpoint-max-sm) {
			.container-fluid, .row {
				margin: 0; padding: 0;
			}
		}

		.service {
			padding-top: 15px;
			position: relative;
			margin-bottom: $content-base-padding-sm * 1.5;
			font-size: 1rem;
			line-height: 1.5rem;

			@media screen and (min-width: $breakpoint-min-md) {
				font-size: 1.075rem;
				line-height: 1.55rem;
				padding-top: 20px;
				margin-bottom: $content-base-padding-md * 1.5;
				margin-left: $content-base-padding-md;
			}

			@media screen and (min-width: $breakpoint-min-lg) {
				font-size: 1.125rem;
				line-height: 1.7rem;
				margin-bottom: $content-base-padding-lg * 1.5;
				margin-left: $content-base-padding-lg;
			}

			&:before {
				width: 40px; height: 2px;
				position: absolute;
				top: 0; left: 0;
				background: #fff;
				content: '';
			}

			.description {
				margin-left: 5px;
				opacity: 0.5;
			}
		}
	}

	a.btn {
		display: block;
		margin-bottom: 20px;
	}
}
