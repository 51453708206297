$breakpoint-min-sm: 550px;
$breakpoint-max-sm: 767px;
$breakpoint-min-md: 768px;
$breakpoint-max-md: 991px;
$breakpoint-min-lg: 992px;
$breakpoint-max-lg: 1279px;
$breakpoint-min-xl: 1280px;
$breakpoint-max-xl: 1899px;
$breakpoint-min-xxl: 1900px;

$content-base-padding-sm: 20px;
$content-base-padding-md: 30px;
$content-base-padding-lg: 40px;