// fade animation
.fade-enter, .fade-leave-to { opacity: 0; }
.fade-enter-active, .fade-leave-active { transition: opacity 1s; }
.fade-enter-to, .fade-leave { opacity: 1}

// modal transition animation
.modal-transition-enter, .modal-transition-leave-to {
	#overlay {opacity: 0 !important;}
	#modal {opacity: 0 !important;}
}
.modal-transition-enter-active, .modal-transition-leave-active {
	$transitionDuration: 0.25s;
	transition: opacity $transitionDuration;

	#overlay {transition: opacity $transitionDuration !important;}
	#modal {transition: opacity $transitionDuration !important;}
}
.modal-transition-enter-to, .modal-transition-leave {
	#overlay {opacity: 1 !important;}
	#modal {opacity: 1 !important;}
}